<template>
  <div class="flex justify-center">
    <div class="flex max-w-5xl w-full px-8">
      <div class="lg:w-1/5 w-1/6 flex flex-col py-8 items-stretch">
        <div class="py-2">
          <a
            class="flex"
            @click="
              open(
                `https://facebook.com/sharer/sharer.php?u=${getURI()}`,
                'Share to Facebook',
              )
            "
            :href="`https://facebook.com/sharer/sharer.php?u=${getURI()}`"
            target="_blank"
            rel="noopener"
            aria-label="Facebook"
          >
            <div
              class="flex items-center text-white rounded pl-1 pr-2 py-1"
              style="background-color: #4267b2"
            >
              <div aria-hidden="true" class="">
                <svg
                  class="h-5 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5
                    9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"
                  />
                </svg>
              </div>
              <span class="ml-1 hidden md:inline-block">Facebook</span>
            </div>
          </a>
        </div>

        <div class="py-2">
          <a
            class="flex"
            @click="
              open(
                `https://www.linkedin.com/shareArticle?mini=true&amp;url=${getURI()}&amp;title=${
                  article.title
                }&summary=${article.keytakeaway}&source=www.mrc-asia.com`,
                'Share to Linkedin',
              )
            "
            :href="`https://www.linkedin.com/shareArticle?mini=true&amp;url=${getURI()}&amp;title=${
              article.title
            }&summary=${article.keytakeaway}&source=www.mrc-asia.com`"
            target="_blank"
            rel="noopener"
            aria-label="LinkedIn"
          >
            <div
              class="flex items-center text-white rounded pl-1 pr-2 py-1"
              style="background-color: #0e76a8"
            >
              <div aria-hidden="true" class="pl-1">
                <svg
                  class="h-5 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0
                  2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5
                  4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z"
                  />
                </svg>
              </div>
              <span class="ml-1 hidden md:inline-block">LinkedIn</span>
            </div>
          </a>
        </div>

        <div class="py-2">
          <a
            class="flex"
            @click="
              open(
                `https://twitter.com/intent/tweet/?text=${
                  article.title
                }%20${getURI()}`,
                'Share to Twitter',
              )
            "
            :href="`https://twitter.com/intent/tweet/?text=${
              article.title
            }%20${getURI()}`"
            target="_blank"
            rel="noopener"
            aria-label="Twitter"
          >
            <div
              class="flex items-center text-white rounded pl-1 pr-2 py-1"
              style="background-color: #00acee"
            >
              <div aria-hidden="true" class="pl-1">
                <svg
                  class="h-5 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96
                    1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5
                    0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6
                    1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0
                    2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25
                    3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92
                    12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"
                  />
                </svg>
              </div>
              <span class="ml-1 hidden md:inline-block">Twitter</span>
            </div>
          </a>
        </div>
      </div>

      <div class="lg:w-4/5 w-5/6 flex flex-col">
        <h1 class="text-4xl font-bold py-8">{{ article.title }}</h1>
        <h2 class="font-medium text-2xl pb-4" v-if="article.subtitle">
          {{ article.subtitle }}
        </h2>
        <div class="py-4 font-medium opacity-75">{{ article.month }}</div>
        <template v-for="(content, i) in article.content">
          <p
            class="py-5 text-black text-lg leading-8 text-justify"
            v-if="content.type === 'text'"
            :key="i"
          >
            {{ content.text }}
          </p>
          <p
            class="py-5 text-black text-lg leading-8 text-justify"
            v-if="content.type === 'html'"
            :key="i"
            v-html="content.html"
          ></p>
          <div
            class="pb-0 pt-8 font-medium leading-8 text-2xl"
            v-if="content.type === 'title'"
            :key="i"
          >
            {{ content.title }}
          </div>
          <div class="overflow-x-auto" :key="i" v-if="content.type === 'img'">
            <img
              class="py-5 max-w-none"
              :src="content.img"
              :alt="content.alt"
              :style="`width:${content.width};`"
            />
          </div>
        </template>

        <div class="relative w-full">
          <p
            class="pt-5 text-black text-lg leading-8 text-justify text-opacity-75 blur"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ultrices
            eros in cursus turpis massa tincidunt dui. Rhoncus dolor purus non
            enim praesent elementum facilisis leo vel. Vitae justo eget magna
            fermentum. Sapien pellentesque habitant morbi tristique. Tincidunt
            dui ut ornare lectus sit amet est placerat. Sit amet aliquam id diam
            maecenas ultricies. Magna ac placerat vestibulum lectus mauris
            ultrices eros in. Sed risus pretium quam vulputate dignissim.
            Viverra ipsum nunc aliquet bibendum enim facilisis gravida neque
            convallis. Ultrices mi tempus
          </p>
          <p
            class="hidden md:block pb-5 text-black text-lg
              leading-8 text-justify text-opacity-75 blur"
          >
            imperdiet nulla malesuada pellentesque elit. Cursus mattis molestie
            a iaculis at erat pellentesque adipiscing. Risus viverra adipiscing
            at in tellus integer feugiat. At lectus urna duis convallis.
            Volutpat diam ut venenatis tellus in metus vulputate eu scelerisque.
          </p>
          <div class="absolute top-0 flex justify-center w-full py-6 mt-3">
            <div
              class="w-120 py-6 px-8 bg-gray-200 elevation-2 hover:elevation-6"
            >
              <h3 class="text-3xl font-semibold">Members Only Access</h3>
              <h4 class="py-3 flex">
                Already our members?
                <div
                  @click="memberGoto($router, '/market_insights_members')"
                  class="pl-2 cursor-pointer text-blue-600 font-medium"
                >
                  Sign in
                </div>
              </h4>
              <p class="py-3 font-semibold text-sm">
                "Executive Business Intelligence" (EBI) membership includes 12
                monthly issues of C&B/HR/Predictive Analytics updates/articles
                PLUS APAC Salary Increase Forecast/Econ Guide twice per year.
              </p>
              <div class="py-3 flex">
                To enroll, contact us at
                <a
                  href=
  "mailto:enquiry@mrc-asia.com?subject=Executive Business Intelligence Subscription Enquiry"
                  class="pl-2 cursor-pointer text-blue-600 font-medium"
                >
                  enquiry@mrc-asia.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import memberGoto from '@/util/memberGoto';
import articles from '@/assets/articles/articles.json';
import articles2 from '@/assets/articles/articles2.json';
import articles3 from '@/assets/articles/articles3.json';
import articles4 from '@/assets/articles/articles4.json';
import articles5 from '@/assets/articles/articles5.json';
import articles6 from '@/assets/articles/articles6.json';

function shuffle(array) {
  array.sort(() => Math.random() - 0.5);
}

export default Vue.extend({
  props: {
    articleId: {
      type: String,
    },
  },
  data() {
    return {
      articles: {
        ...articles6, ...articles5, ...articles4, ...articles3, ...articles2, ...articles,
      },
    };
  },
  methods: {
    memberGoto,
    open(url, pagename) {
      window.open(url, pagename, 'resizable,height=550,width=650');
    },
    getURI() {
      if (!this.articleId) {
        return encodeURI('https://www.mrc-asia.com');
      }
      return encodeURI(
        `https://www.mrc-asia.com/market_insights/ebi/${this.articleId}`,
      );
    },
  },
  computed: {
    articlesWithContent() {
      const keys = Object.keys(this.articles).filter(
        (k) => k !== this.articleId
          && this.articles[k].content
          && this.articles[k].content.length > 0,
      );
      shuffle(keys);
      const shuffledArticles = {};
      for (let i = 0; i < 4; i += 1) {
        shuffledArticles[keys[i]] = this.articles[keys[i]];
      }
      return shuffledArticles;
    },
    article() {
      if (!this.articleId) {
        return { title: 'Article not found', content: [] };
      }
      if (!this.articles[this.articleId]) {
        return { title: 'Article not found', content: [] };
      }
      return this.articles[this.articleId];
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      document
        .querySelector('meta[property="og:url"]')
        .setAttribute('content', `https://www.mrc-asia.com${to.fullPath}`);
      if (vm.article && vm.article.title) {
        document.title = `MRC - ${vm.article.title}`;
        document
          .querySelector('meta[property="og:title"]')
          .setAttribute('content', `MRC - ${vm.article.title}`);
      }
      if (vm.article && vm.article.keytakeaway) {
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', vm.article.keytakeaway);
        document
          .querySelector('meta[property="og:description"]')
          .setAttribute('content', vm.article.keytakeaway);
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();
    document
      .querySelector('meta[property="og:url"]')
      .setAttribute('content', `https://www.mrc-asia.com${to.fullPath}`);
    if (this.article && this.article.title) {
      document.title = `MRC - ${this.article.title}`;
      document
        .querySelector('meta[property="og:title"]')
        .setAttribute('content', `MRC - ${this.article.title}`);
    }
    if (this.article && this.article.keytakeaway) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', this.article.keytakeaway);
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute('content', this.article.keytakeaway);
    }
  },
});
</script>

<style scoped>
.blur {
  color: transparent;
  text-shadow: 0 0 10px #000;
}
</style>
