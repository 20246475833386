<template>
  <div class="flex flex-col justify-center">
    <!-- Hero section with background image, heading, subheading and button -->
    <div
      class="relative overflow-hidden bg-cover bg-no-repeat w-full hero-bg"
      style="
        height: 150px;
      ">
      <div
        class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
        style="background-color: rgba(255, 255, 255, 0.15)">
      </div>
    </div>
    <div class="w-full">
      <div class="flex h-full items-center justify-start m-auto max-w-7xl">
        <div class="relative -mt-16 px-2 py-4">
          <div
            class="w-96 block rounded bg-white p-6 dark:bg-neutral-700 multi-shadow">
            <h1 class="font-semibold text-2xl p-4">MRC Video</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- Hero section with background image, heading, subheading and button -->
    <div class="w-full flex justify-center">
      <div class="flex max-w-7xl w-full px-8">
        <div class="lg:w-1/5 w-1/6 flex flex-col py-8 items-stretch">
          <div class="py-2">
            <a
              class="flex"
              @click="
                open(
                  `https://facebook.com/sharer/sharer.php?u=${getURI()}`,
                  'Share to Facebook',
                )
              "
              :href="`https://facebook.com/sharer/sharer.php?u=${getURI()}`"
              target="_blank"
              rel="noopener"
              aria-label="Facebook"
            >
              <div
                class="flex items-center text-white rounded pl-1 pr-2 py-1"
                style="background-color: #4267b2"
              >
                <div aria-hidden="true" class="">
                  <svg
                    class="h-5 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5
                      9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"
                    />
                  </svg>
                </div>
                <span class="ml-1 hidden md:inline-block">Facebook</span>
              </div>
            </a>
          </div>

          <div class="py-2">
            <a
              class="flex"
              @click="
                open(
                  `https://www.linkedin.com/shareArticle?mini=true&amp;url=${getURI()}&amp;title=${
                    videoList.title
                  }&summary=${videoList.keytakeaway}&source=www.mrc-asia.com`,
                  'Share to Linkedin',
                )
              "
              :href="`https://www.linkedin.com/shareArticle?mini=true&amp;url=${getURI()}&amp;title=${
                videoList.title
              }&summary=${videoList.keytakeaway}&source=www.mrc-asia.com`"
              target="_blank"
              rel="noopener"
              aria-label="LinkedIn"
            >
              <div
                class="flex items-center text-white rounded pl-1 pr-2 py-1"
                style="background-color: #0e76a8"
              >
                <div aria-hidden="true" class="pl-1">
                  <svg
                    class="h-5 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0
                    2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5
                    4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z"
                    />
                  </svg>
                </div>
                <span class="ml-1 hidden md:inline-block">LinkedIn</span>
              </div>
            </a>
          </div>

          <div class="py-2">
            <a
              class="flex"
              @click="
                open(
                  `https://twitter.com/intent/tweet/?text=${
                    videoList.title
                  }%20${getURI()}`,
                  'Share to Twitter',
                )
              "
              :href="`https://twitter.com/intent/tweet/?text=${
                videoList.title
              }%20${getURI()}`"
              target="_blank"
              rel="noopener"
              aria-label="Twitter"
            >
              <div
                class="flex items-center text-white rounded pl-1 pr-2 py-1"
                style="background-color: #00acee"
              >
                <div aria-hidden="true" class="pl-1">
                  <svg
                    class="h-5 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96
                      1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5
                      0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6
                      1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0
                      2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25
                      3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92
                      12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"
                    />
                  </svg>
                </div>
                <span class="ml-1 hidden md:inline-block">Twitter</span>
              </div>
            </a>
          </div>
        </div>

        <div class="lg:w-4/5 w-5/6 flex flex-col">
          <h1 class="text-4xl font-bold py-8">{{ videoList.title }}</h1>
          <h2 class="font-medium text-2xl pb-4" v-if="videoList.subtitle">
            {{ videoList.subtitle }}
          </h2>
          <div class="py-4 font-medium opacity-75">{{ videoList.month }}</div>
          <video
            class="focus:outline-none w-full"
            controls
            preload="auto"
            ref="videoDemo"
            v-if='videoList.content.path !== ""'
          >
            <source
              ref="sourceVideoDemo"
              type="video/mp4"
            >
          </video>

          <div class="relative w-full" v-if='videoList.content.path === ""'>
            <p
              class="pt-5 text-black text-lg leading-8 text-justify text-opacity-75 blur"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ultrices
              eros in cursus turpis massa tincidunt dui. Rhoncus dolor purus non
              enim praesent elementum facilisis leo vel. Vitae justo eget magna
              fermentum. Sapien pellentesque habitant morbi tristique. Tincidunt
              dui ut ornare lectus sit amet est placerat. Sit amet aliquam id diam
              maecenas ultricies. Magna ac placerat vestibulum lectus mauris
              ultrices eros in. Sed risus pretium quam vulputate dignissim.
              Viverra ipsum nunc aliquet bibendum enim facilisis gravida neque
              convallis. Ultrices mi tempus
            </p>
            <p
              class="hidden md:block pb-5 text-black text-lg
                leading-8 text-justify text-opacity-75 blur"
            >
              imperdiet nulla malesuada pellentesque elit. Cursus mattis molestie
              a iaculis at erat pellentesque adipiscing. Risus viverra adipiscing
              at in tellus integer feugiat. At lectus urna duis convallis.
              Volutpat diam ut venenatis tellus in metus vulputate eu scelerisque.
            </p>
            <div class="absolute top-0 flex justify-center w-full py-6 mt-3">
              <div
                class="w-120 py-6 px-8 bg-gray-200 elevation-2 hover:elevation-6"
              >
                <h3 class="text-3xl font-semibold">Video Not Found</h3>
                <div class="py-3 flex">
                  Enquiry, contact us at
                  <a
                    href=
    "mailto:enquiry@mrc-asia.com?subject=Introduction Video"
                    class="pl-2 cursor-pointer text-blue-600 font-medium"
                  >
                    enquiry@mrc-asia.com
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-20">&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import memberGoto from '@/util/memberGoto';
import videos from '@/assets/videos/videos.json';
import { accessRights } from '../../api/private/rpcs';
import { signInCall } from '../../api/public/rpcs';

export default Vue.extend({
  props: {
    videoId: {
      type: String,
    },
  },
  data() {
    return {
      videosList: {
        ...videos,
      },
    };
  },
  methods: {
    memberGoto,
    open(url, pagename) {
      window.open(url, pagename, 'resizable,height=550,width=650');
    },
    getURI() {
      if (!this.videoId) {
        return encodeURI('https://www.mrc-asia.com');
      }
      return encodeURI(
        `https://www.mrc-asia.com/videos/${this.videoId}`,
      );
    },
  },
  computed: {
    videoList() {
      if (!this.videoId) {
        return { title: 'Video not found', content: [] };
      }
      if (!this.videosList[this.videoId]) {
        return { title: 'Video not found', content: [] };
      }
      return this.videosList[this.videoId];
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      document
        .querySelector('meta[property="og:url"]')
        .setAttribute('content', `https://www.mrc-asia.com${to.fullPath}`);
      if (vm.videoList && vm.videoList.title) {
        document.title = `MRC - ${vm.videoList.title}`;
        document
          .querySelector('meta[property="og:title"]')
          .setAttribute('content', `MRC - ${vm.videoList.title}`);
      }
      if (vm.videoList && vm.videoList.keytakeaway) {
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', vm.videoList.keytakeaway);
        document
          .querySelector('meta[property="og:description"]')
          .setAttribute('content', vm.videoList.keytakeaway);
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();
    document
      .querySelector('meta[property="og:url"]')
      .setAttribute('content', `https://www.mrc-asia.com${to.fullPath}`);
    if (this.videoList && this.videoList.title) {
      document.title = `MRC - ${this.videoList.title}`;
      document
        .querySelector('meta[property="og:title"]')
        .setAttribute('content', `MRC - ${this.videoList.title}`);
    }
    if (this.videoList && this.videoList.keytakeaway) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', this.videoList.keytakeaway);
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute('content', this.videoList.keytakeaway);
    }
  },
  mounted() {
    signInCall({
      userid: 'intro@mrc-asia.com',
      password: 'yjPXFIwn',
    }).then(() => {
      accessRights().then((data) => {
        if (data) {
          this.$refs.sourceVideoDemo.setAttribute(
            'src',
            `/api/private/seminar/video/${this.videoList.content[0].path}`,
            // '/api/private/seminar/video/',
          );
          this.$refs.videoDemo.load();
        }
      });
    });
  },
});
</script>

<style scoped>
.blur {
  color: transparent;
  text-shadow: 0 0 10px #000;
}
.hero-bg {
  background: url('../../assets/img/tools_economy_banner.jpg') center;
  background-size: cover;
  background-repeat: no-repeat;
}
.b4footer-bg {
  background: url('../../assets/img/background/testimonial.jpg') top;
}
.multi-shadow {
  /* box-shadow: -10px 10px 0px rgba(33, 33, 33, 0.7), -20px 20px 0px rgba(33, 33, 33, 0.4), -30px 30px 0px rgba(33, 33, 33, 0.1); */
  box-shadow:
  5px 5px 0 0 rgba(36, 11, 54,0.3),
  10px 10px 0 0 rgba(241, 39, 17,0.2),
  15px 15px 0 0 rgba(195, 20, 50,0.1);
}
</style>
