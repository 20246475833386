<template>
  <div class="flex justify-center py-3">
    <div class="max-w-5xl w-full flex flex-col">
      <h2 class="text-2xl font-semibold p-4 border-b-2 border-red-600">
        Executive Business Intelligence monthly newsletters
      </h2>

      <img
        src="@/assets/img/ebi_banner.jpg"
        class="object-cover w-full h-104 mt-4"
        alt="EBI Banner"
      />

      <div class="flex flex-wrap items-stretch mt-4">
        <div
          class="lg:w-1/2 w-full p-2 min-h-0"
          v-for="(article, title) in articles"
          :key="title"
        >
          <div class="h-full flex flex-col p-8 elevation-2 hover:elevation-6">
            <h3 class="text-orange-800 font-semibold pb-4">
              {{ article.month }}
            </h3>
            <div class="text-orange-900 font-bold pb-4">
              {{ article.title }}
            </div>
            <div class="leading-snug text-sm text-justify">
              {{ article.keytakeaway }}
            </div>
            <router-link
              :to="'/market_insights/ebi/' + title"
              class="text-blue-600 text-sm font-semibold tracking-tighter
                pr-2 pt-2 text-right hover:underline cursor-pointer"
            >
              Read more...
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import articles from '@/assets/articles/articles.json';
import articles2 from '@/assets/articles/articles2.json';
import articles3 from '@/assets/articles/articles3.json';
import articles4 from '@/assets/articles/articles4.json';
import articles5 from '@/assets/articles/articles5.json';
import articles6 from '@/assets/articles/articles6.json';

export default Vue.extend({
  data() {
    return {
      articles: {
        ...articles6, ...articles5, ...articles4, ...articles3, ...articles2, ...articles,
      },
    };
  },
});
</script>

<style>
</style>
