<template>
  <keep-alive>
    <router-view></router-view>
  </keep-alive>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({});
</script>

<style>

</style>
